import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
          <path d="M5940 11850 c-621 -57 -1177 -198 -1737 -441 -387 -169 -835 -435
-1153 -688 -342 -271 -644 -565 -890 -867 -94 -115 -298 -399 -360 -499 -145
-235 -191 -316 -261 -450 -162 -313 -340 -763 -404 -1025 -9 -36 -20 -76 -25
-90 -28 -81 -105 -460 -130 -645 -44 -329 -63 -832 -40 -1115 54 -692 194
-1258 465 -1875 64 -145 155 -328 229 -455 27 -47 56 -98 66 -115 32 -55 119
-189 202 -310 269 -391 601 -758 959 -1060 341 -289 738 -550 1119 -737 873
-429 1860 -623 2805 -553 110 8 232 19 270 25 39 5 108 14 155 20 236 31 618
121 900 212 125 41 351 124 420 155 30 14 79 36 108 49 29 13 55 24 57 24 7 0
293 143 340 170 22 12 75 42 118 67 215 122 479 303 672 462 11 9 40 32 65 51
237 183 657 614 842 865 5 5 23 28 40 51 18 22 56 73 83 113 28 40 59 85 71
99 32 41 174 265 235 372 182 316 388 790 483 1110 81 276 75 252 120 455 19
84 62 331 71 400 3 28 10 79 16 115 19 135 39 463 39 663 0 458 -72 990 -192
1422 -156 562 -369 1043 -671 1515 -68 107 -249 363 -299 425 -9 11 -38 47
-64 80 -84 105 -219 257 -320 360 -625 636 -1330 1085 -2134 1359 -310 106
-440 142 -725 201 -471 97 -1058 129 -1545 85z m495 -1505 c11 -22 29 -65 39
-95 10 -30 25 -65 32 -77 8 -12 14 -27 14 -33 0 -6 8 -29 19 -53 10 -23 29
-69 41 -102 12 -33 26 -69 31 -80 55 -129 89 -212 89 -223 0 -7 4 -20 10 -30
15 -26 78 -178 105 -252 22 -60 87 -221 119 -294 8 -17 16 -40 19 -51 3 -11
30 -80 61 -154 31 -74 56 -138 56 -142 0 -5 13 -36 29 -71 16 -35 50 -117 76
-183 25 -66 61 -156 80 -200 18 -44 43 -107 55 -140 12 -33 31 -79 41 -102 11
-24 19 -45 19 -48 0 -2 11 -29 25 -58 13 -30 32 -76 41 -103 9 -27 34 -89 54
-139 21 -49 50 -121 65 -160 15 -38 35 -88 44 -110 10 -22 33 -78 51 -125 18
-47 50 -125 70 -175 21 -49 56 -139 80 -200 44 -115 52 -134 94 -228 14 -32
26 -66 26 -75 0 -10 4 -22 8 -27 5 -6 26 -53 47 -105 21 -52 43 -108 50 -125
64 -155 128 -316 135 -340 4 -16 30 -82 57 -145 28 -63 65 -153 83 -200 18
-47 41 -103 51 -125 10 -22 31 -76 48 -120 16 -44 47 -123 69 -175 57 -135
141 -345 167 -415 27 -72 27 -73 83 -205 24 -58 49 -111 54 -117 8 -10 78 -13
290 -13 l279 0 -3 -42 -3 -43 -1202 -3 -1202 -2 -11 21 c-7 11 -9 32 -6 45 l6
24 325 0 c268 0 325 2 325 14 0 7 -9 35 -19 62 -44 111 -140 362 -152 397 -7
20 -17 37 -21 37 -4 0 -8 10 -8 23 0 13 -4 27 -9 32 -5 6 -32 73 -61 150 -29
77 -64 165 -77 195 -14 30 -32 78 -40 105 -8 28 -26 73 -38 100 -13 28 -40 93
-60 145 -20 52 -61 156 -91 231 -30 74 -54 139 -54 144 0 5 -8 27 -19 49 -25
54 -121 299 -121 308 0 4 -13 36 -28 70 -16 35 -38 88 -49 118 -12 30 -53 138
-93 240 -40 102 -81 210 -93 240 -11 30 -33 83 -49 118 -15 34 -28 66 -28 71
0 8 -28 81 -55 146 -7 17 -23 55 -36 85 -12 30 -26 59 -31 64 -4 6 -8 17 -8
25 0 8 -27 83 -59 166 -33 82 -72 181 -87 220 -27 70 -36 93 -73 177 -12 26
-21 51 -21 56 0 9 -61 162 -90 227 -10 22 -22 56 -28 75 -11 39 -43 121 -73
186 -10 24 -19 48 -19 53 0 6 -7 11 -15 11 -8 0 -15 -7 -15 -15 0 -8 -11 -38
-24 -67 -13 -29 -36 -82 -50 -118 -15 -36 -31 -73 -36 -82 -6 -10 -10 -22 -10
-28 0 -5 -13 -39 -30 -75 -16 -36 -30 -68 -30 -70 0 -2 -11 -29 -25 -59 l-25
-54 -34 10 c-19 6 -38 17 -41 25 -3 8 8 45 25 83 16 38 30 72 30 77 0 4 29 78
64 163 35 85 81 198 101 250 21 52 46 114 56 137 11 23 19 45 19 49 0 9 72
187 106 262 13 29 24 56 24 61 0 9 43 116 75 186 12 28 30 71 38 95 25 75 56
152 106 262 11 26 21 51 21 56 0 9 60 159 90 227 10 22 21 51 25 65 18 60 35
81 63 78 20 -2 32 -13 47 -43z m-385 -3385 c135 -12 217 -27 226 -41 10 -17 7
-81 -5 -88 -6 -4 -44 -8 -85 -9 -166 -6 -293 -116 -415 -362 -23 -46 -41 -87
-41 -91 0 -5 -11 -32 -24 -62 -30 -64 -83 -209 -96 -257 -9 -39 -86 -264 -100
-295 -5 -11 -16 -47 -25 -80 -61 -226 -222 -580 -327 -721 -21 -28 -38 -54
-38 -59 0 -4 -53 -60 -118 -124 -80 -80 -144 -132 -202 -167 -113 -69 -151
-89 -175 -96 -11 -3 -51 -17 -90 -31 -38 -14 -119 -38 -180 -54 -109 -28 -112
-28 -405 -27 -334 2 -400 10 -410 54 -11 45 -2 56 63 69 59 11 166 48 177 60
3 4 24 19 47 34 45 31 91 78 146 150 41 54 133 240 167 337 12 36 39 112 60
170 21 58 48 139 60 180 44 148 63 206 95 295 18 50 41 113 50 140 31 84 151
325 211 420 198 316 470 526 802 619 150 42 400 56 632 36z m-2591 -3057 c11
-56 126 -673 196 -1058 19 -104 37 -198 39 -207 4 -16 -7 -18 -120 -18 -142 0
-124 -17 -146 135 l-13 90 -96 3 c-112 3 -99 17 -120 -135 l-13 -93 -123 0
c-96 0 -123 3 -123 13 0 8 14 85 30 173 42 222 67 354 85 459 9 50 31 171 50
270 18 99 43 231 54 293 12 63 24 118 27 123 3 5 63 9 133 9 l128 0 12 -57z
m551 -613 l0 -670 -125 0 -125 0 0 663 c0 365 3 667 7 670 3 4 60 7 125 7
l118 0 0 -670z m535 410 c35 -140 71 -291 81 -335 l17 -80 23 105 c13 58 50
209 82 335 l59 230 147 3 146 3 0 -671 0 -670 -116 0 -116 0 4 414 c2 228 3
414 2 413 -1 -1 -9 -36 -18 -77 -26 -126 -126 -564 -131 -577 -3 -9 -29 -13
-83 -13 l-78 0 -23 98 c-22 94 -46 199 -77 332 -8 36 -23 101 -33 145 l-20 80
0 -407 -1 -408 -115 0 -115 0 0 663 c0 365 3 667 7 671 4 3 72 5 151 4 l145
-3 62 -255z m1139 252 c4 -7 46 -230 156 -827 10 -55 35 -187 54 -293 20 -106
36 -197 36 -203 0 -5 -53 -9 -123 -9 l-123 0 -13 93 c-21 152 -8 138 -120 135
l-96 -3 -17 -110 c-9 -60 -17 -111 -17 -112 -1 -2 -57 -3 -124 -3 l-124 0 18
98 c10 53 52 282 94 507 41 226 85 460 96 520 11 61 25 134 30 163 l10 52 129
0 c71 0 131 -4 134 -8z m646 -370 c71 -206 130 -375 132 -377 2 -2 2 167 0
376 l-3 379 110 0 111 0 0 -670 0 -671 -101 3 -101 3 -57 160 c-31 88 -88 248
-125 355 -38 107 -71 200 -75 207 -3 6 -5 -155 -3 -358 l3 -369 -110 0 -111 0
0 670 0 671 101 -3 101 -3 128 -373z m1244 363 c155 -33 240 -128 272 -305 16
-91 19 -594 4 -688 -25 -151 -83 -242 -187 -294 l-68 -33 -207 -3 -208 -4 0
664 c0 366 3 668 7 671 13 13 317 7 387 -8z m834 -47 c6 -35 31 -173 56 -308
25 -135 61 -333 81 -440 20 -107 49 -266 65 -353 17 -88 30 -163 30 -168 0 -5
-56 -9 -124 -9 l-123 0 -12 93 c-21 152 -8 138 -119 135 l-96 -3 -14 -90 c-7
-49 -15 -100 -18 -112 -4 -22 -8 -23 -130 -23 -115 0 -125 1 -120 18 3 9 23
114 45 232 22 118 60 328 86 465 25 138 57 311 71 385 13 74 29 159 35 188
l10 52 133 0 133 0 11 -62z m872 -38 l0 -100 -100 0 c-93 0 -100 -1 -101 -20
-1 -11 -3 -29 -4 -40 -1 -11 -3 -258 -4 -550 l-1 -530 -120 0 -120 0 0 570 0
570 -105 0 -105 0 0 93 c0 52 3 97 7 100 3 4 152 7 330 7 l323 0 0 -100z m500
94 c0 -5 35 -199 95 -519 14 -77 32 -174 40 -215 7 -41 34 -186 59 -322 25
-136 46 -254 46 -262 0 -14 -18 -16 -119 -16 -137 0 -123 -11 -141 115 -17
125 -8 115 -115 115 -65 0 -94 -4 -98 -12 -2 -7 -10 -59 -18 -115 l-13 -103
-128 0 c-98 0 -126 3 -123 13 3 6 20 98 39 202 19 105 47 253 61 330 14 77 44
235 65 350 21 116 48 263 60 328 l22 117 134 0 c74 0 134 -3 134 -6z"/>
<path d="M3310 3569 c0 -8 -13 -126 -30 -264 -16 -137 -30 -253 -30 -257 0 -5
29 -8 65 -8 57 0 65 2 65 19 0 50 -58 512 -65 520 -3 2 -5 -2 -5 -10z"/>
<path d="M5546 3543 c-3 -21 -13 -99 -21 -173 -9 -74 -20 -168 -26 -208 -16
-128 -19 -122 56 -122 36 0 65 4 65 8 0 5 -9 80 -19 168 -11 87 -25 205 -31
262 -7 56 -14 102 -15 102 -2 0 -6 -17 -9 -37z"/>
<path d="M7437 3764 c-4 -4 -7 -223 -7 -487 l0 -480 52 6 c32 4 60 14 73 27
45 42 50 90 50 465 0 337 -1 352 -22 397 -11 25 -29 51 -40 56 -26 14 -97 24
-106 16z"/>
<path d="M8257 3555 c-3 -16 -11 -75 -17 -130 -6 -55 -18 -151 -26 -213 -8
-62 -14 -126 -14 -143 l0 -29 65 0 c36 0 65 4 65 9 0 5 -4 43 -10 83 -5 40
-19 154 -30 253 -24 207 -25 210 -33 170z"/>
<path d="M9641 3579 c-1 -9 -14 -125 -31 -257 -16 -133 -30 -251 -30 -262 0
-18 6 -20 65 -20 53 0 65 3 65 16 0 24 -60 517 -65 529 -2 6 -4 3 -4 -6z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
